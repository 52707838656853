<template>
  <div class="container">
    <div class="about">
      <h1>
        {{ $t('NOTIFICATIONS.title') }}
      </h1>

      <div class="notifications-list" v-if="notifications && notifications.length > 0">
        <div class="notification-card" v-for="notification in notifications" :key="notification.id">
          <p class="date">{{notification.created_at_friendly}}</p>
          <div class="sender" v-if="notification.sender">
            <router-link
              :to="{ name: 'UserSingle', params: { id: notification.sender.to_param } }"
            >{{notification.sender.display_name}}</router-link>
          </div>
          {{notification.content}}
          <div class="trackable" v-if="notification.trackable_type == 'Match'">
            <router-link
              :to="{ name: 'MatchSingle', params: { id: notification.trackable_id } }"
            >
              {{ $t('UTILITY.see_match') }}
            </router-link>
          </div>
          <!-- {{notification.key == 'share_match'}} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notifications",
  data() {
    return {
      notifications: []
    };
  },
  metaInfo() {
    return {
      title: "Notificações"
    };
  },
  methods: {
    initialize() {
      this.$http
        .get(`notifications.json`)
        .then(response => (this.notifications = response.data));
    }
  },
  mounted() {
    this.initialize();
  },
  components: {}
};
</script>

<style lang="sass" scoped>
.notification-card
  background: #f1f1f1
  margin-bottom: 10px
  padding: 10px
</style>

